import React from "react";
import ProductCrumb from "../components/productDetails/ProductCrumb";
import BlogPost from "../components/blogs/BlogPost";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const { id } = useParams;
  return (
    <>
      <ProductCrumb title="Blog Details" />
      <BlogPost id={id} />
    </>
  );
};

export default BlogDetails;
