// In SignUpPage.js or a similar file
import React from 'react';
import { SignUp } from '@clerk/clerk-react';

export default function SignUpPage() {
  return (
    <div className="flex flex-col items-center justify-center pt-10 pb-14">
      <h2 className="text-2xl font-semibold text-center mb-6">
        CREATE AN ACCOUNT
      </h2>
      <SignUp />
    </div>
  );
}
