/* eslint-disable */
import { FaTimes } from "react-icons/fa";
import useStripeCheckout from "../hooks/useStripeCheckout";
import { useUser } from "@clerk/clerk-react";
import useFlutterwaveCheckout from "../hooks/useFlutterwaveCheckout";
import { useNavigate } from "react-router-dom";

const PaymentModal = ({ setShowModal, productItem }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const handleStripeCheckout = useStripeCheckout();
  const handleFlutterCheckout = useFlutterwaveCheckout();

  const handleStripeBuy = () => {
    if (!user) {
      navigate("/sign-in");
      return;
    }
    const stripeProduct = { ...productItem, quantity: 1 };
    handleStripeCheckout(stripeProduct, user);
  };

  console.log("productItem--->", productItem);

  const handleFlutterBuy = () => {
    if (!user) {
      navigate("/sign-in");
      return;
    }
    const initiatePayment = handleFlutterCheckout({
      amount: productItem.price, // Assuming you have price in gameItem
      productDetails: productItem,
      user: user,
    });

    initiatePayment(); // This calls the function that actually handles the payment
    console.log("Payment initiated");
  };

  const handleClose = (e) => {
    if (e.target.id === "target") {
      setShowModal(false);
    }
  };
  return (
    <div
      className="fixed inset-0 z-10 flex h-screen w-screen items-center justify-center bg-black bg-opacity-25 px-10 backdrop-blur-sm md:px-0"
      id="target"
      onClick={handleClose}
    >
      <div className="w-[90%] md:w-[25rem] relative ">
        <div className="flex flex-col items-center justify-center rounded-[5.14px] bg-white px-[2.5rem] pt-6 pb-8 shadow-lg">
          <FaTimes
            className="absolute top-3 right-3 cursor-pointer"
            onClick={() => setShowModal(false)}
          />
          <img src="/card.png" alt="modal" className="object-contain w-24" />
          <h2 className="text-dark text-red-500 mt-1 mb-3 text-2xl font-semibold leading-[3.8rem]">
            Select Payment Method
          </h2>
          <div className="flex items-center gap-2">
            <div
              className="border border-red-500 px-4 rounded-md hover:bg-red-500 hover:text-white cursor-pointer"
              onClick={handleStripeBuy}
            >
              <img src="/stripelogo.png" className="w-20 h-10 object-contain" />
              {/* <p className="text-dark text-center text-lg font-medium">Payment</p> */}
            </div>
            <div
              onClick={handleFlutterBuy}
              className="border border-red-500 px-2 rounded-md hover:bg-red-500 hover:text-white cursor-pointer"
            >
              <img
                src="/flutterwave.png"
                className="w-32 h-10 object-contain"
              />
              {/* <p className="text-dark text-center text-lg font-medium">Payment</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
