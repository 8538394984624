import React from "react";
import { Helmet } from "react-helmet";
import BlogDetails from "../components/blogs/BlogDetails";
import ProductCrumb from "../components/productDetails/ProductCrumb";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>CDkeyzone — Your Favorite Home For Games and Software</title>
        <meta name="title" content="CDkeyzone" />
        <meta
          name="description"
          content="Your Favorite Home For Games and Softwares"
        />
      </Helmet>
      <ProductCrumb title="Blogs" />
      <BlogDetails />
    </>
  );
};

export default Blog;
