import GameDetails from "../components/games/GamesDetails";
import ProductCrumb from "../components/productDetails/ProductCrumb";
import React from "react";
import { Helmet } from "react-helmet";

const Games = () => {
  const categoryName = "Games";
  return (
    <>
      <Helmet>
        <title>CDkeyzone — Your Favorite Home For Games and Software</title>
        <meta name="title" content="CDkeyzone" />
        <meta
          name="description"
          content="Your Favorite Home For Games and Softwares"
        />
      </Helmet>
      <ProductCrumb title="Games" />
      <GameDetails categoryName={categoryName} />
    </>
  );
};

export default Games;
