import ProductCrumb from "../components/productDetails/ProductCrumb";
import SoftwareDetails from "../components/softwares/SoftwareDetails";
import { Helmet } from "react-helmet";
import React from "react";

const Softwares = () => {
  const categoryName = "Softwares";
  return (
    <>
      <Helmet>
        <title>CDkeyzone — Your Favorite Home For Games and Software</title>
        <meta name="title" content="CDkeyzone" />
        <meta
          name="description"
          content="Your Favorite Home For Games and Softwares"
        />
      </Helmet>
      <div className="bg-[#f6f6f6] w-full">
        <ProductCrumb title="Softwares" />
        <SoftwareDetails categoryName={categoryName} />
      </div>
    </>
  );
};

export default Softwares;
