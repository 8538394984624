import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Softwares from "./pages/Softwares";
import Games from "./pages/Games";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import ProductDetails from "./pages/ProductDetails";
import SearchPage from "./pages/SearchPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import DeliveryTerms from "./pages/DeliveryTerms";
import RefundPolicy from "./pages/RefundPolicy";
import PaymentMethod from "./pages/PaymentMethod";
import SuccessPage from "./pages/SuccessPage";

const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/softwares",
        element: <Softwares />,
      },
      {
        path: "/games",
        element: <Games />,
      },
      {
        path: "/blogs",
        element: <Blog />,
      },
      {
        path: "/search",
        element: <SearchPage />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/delivery-terms",
        element: <DeliveryTerms />,
      },
      {
        path: "/success",
        element: <SuccessPage />,
      },
      {
        path: "/refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "/payment-method",
        element: <PaymentMethod />,
      },
      {
        path: "/blog/:id",
        element: <BlogDetails />,
      },
      {
        path: "/product-details/:id",
        element: <ProductDetails />,
      },
      {
        path: "/sign-in",
        element: <SignInPage />,
      },
      {
        path: "/sign-up",
        element: <SignUpPage />,
      },
    ],
  },
]);

const App = () => {
  return (
    <div className="font-montserrat">
      {/* <ClerkProvider> */}
      <RouterProvider router={router} />
      {/* </ClerkProvider> */}
      <ToastContainer />
    </div>
  );
};

export default App;
