import Details from "../components/productDetails/Details";
import ProductCrumb from "../components/productDetails/ProductCrumb";
import React from "react";
import { useParams } from "react-router-dom";

const ProductDetails = () => {
  const { id } = useParams();
  console.log("id---->", id);
  return (
    <div className="px-4 py-10 bg-[#f6f6f6]">
      <ProductCrumb title="Product-details" />
      <Details productId={id} />
    </div>
  );
};

export default ProductDetails;
