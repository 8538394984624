import ProductCrumb from "../components/productDetails/ProductCrumb";
import React from "react";
import Search from "../components/layout/Search";

const SearchPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const term = urlParams.get("term");
  return (
    <>
      <ProductCrumb title="Search" />
      <Search term={term} />
    </>
  );
};

export default SearchPage;
