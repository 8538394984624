import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import axios from "axios";
import { useEffect, useState } from "react";

async function getUsdToNgnRate() {
  const apiKey = process.env.REACT_APP_EXCHANGE_KEY; // Replace YOUR_API_ACCESS_KEY with your actual API key
  console.log(apiKey)
  try {
      const response = await axios.get(`https://api.exchangeratesapi.io/latest?base=USD&symbols=NGN&access_key=${apiKey}`);
      console.log("API Response:", response.data);
      return response.data.rates.NGN;
  } catch (error) {
      console.error("Failed to fetch currency rate", error);
      return 1000; // Return a fallback rate in case the API fails
  }
}


function useFlutterwaveCheckout() {
  const [usdToNgnRate, setUsdToNgnRate] = useState(1000);
  console.log("usdToNgnRate---->", usdToNgnRate);

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      const rate = await getUsdToNgnRate();
      setUsdToNgnRate(rate);
    };

    fetchCurrencyRate();
  }, []);

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: `ref-${Date.now()}`,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customizations: {
      title: "CDkeyzone",
      description: "Payment for items in cart",
      logo: window.location.origin + "/logo.png",
    },
  };

  const handlePayment = useFlutterwave(config);

  return ({ amount, productDetails, user, callback, onClose }) => {
    const amountInNgn = amount * usdToNgnRate;
    // Update the config based on function arguments
    config.amount = amountInNgn;
    config.customer = {
      email: user?.primaryEmailAddress?.emailAddress,
      name: user?.fullName,
    };

    return () => {
      console.log("payment initiated");
      handlePayment({
        ...config,
        callback: async (response) => {
          console.log(response);
          if (response.status === "successful") {
            // Now verify the payment on your server
            console.log("verifying server");
            try {
              const verifyResponse = await axios.post("/verify-payment", {
                transactionId: response.transaction_id,
              });
              if (verifyResponse.data.verified) {
                console.log("transaction successful");
                callback?.(response); // Handle after verification
              } else {
                console.error("Verification failed");
              }
            } catch (error) {
              console.error("Error verifying payment:", error);
            }
          }
          closePaymentModal(); // Optionally close the modal
        },
        onClose: () => {
          onClose?.();
        },
      });
    };
  };
}

export default useFlutterwaveCheckout;
