// hooks/useStripeCheckout.js
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/checkout`;

const useStripeCheckout = () => {
  const handleCheckout = async (cartItems, user) => {
    console.log(apiUrl);
    console.log("cartItems-------->", cartItems);
    const stripe = await stripePromise;
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: cartItems,  user: { // Add user details to the body of the request
        id: user?.id,
        email: user?.primaryEmailAddress?.emailAddress,
        name: user?.fullName
      } }),
    });
    const { sessionId } = await response.json();

    if (!sessionId) {
      console.error("Session ID is missing.");
      return;
    }

    // Redirect to Stripe's checkout
    const result = await stripe.redirectToCheckout({
      sessionId,
    });
    console.log("running--->", result);
    if (result.error) {
      alert(result.error.message);
    }
  };

  return handleCheckout;
};

export default useStripeCheckout;
